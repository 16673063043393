import React, { useLayoutEffect, useRef, useState } from "react";
import * as S from "./style";

import paralax from "../../../images/paralax.svg";
import Bubble from "../../../components/Bubble/index.js";
import { Container } from "../../../styles/UI";
import { StaticImage } from "gatsby-plugin-image";

import { useViewportScroll, motion, useTransform } from "framer-motion";

export default function DetalhesHome(props) {
  const ref = useRef();
  const [offsetTop, setOffsetTop] = useState(0);

  useLayoutEffect(() => {
    if (!ref.current) return null;
    let bodyRect = document.body.getBoundingClientRect();
    let elementRect = ref.current.getBoundingClientRect();
    setOffsetTop(elementRect.top - bodyRect.top);
  }, [ref]);

  const { scrollY } = useViewportScroll();
  const y1 = useTransform(
    scrollY,
    [offsetTop - 600, offsetTop + 600],
    ["0%", "100%"]
  );

  return (
    <S.Detalhes>
      <Container>
        {/* {props.paralax && (
          <motion.img
            ref={ref}
            style={{ y: y1 }}
            src={paralax}
            className="paralax"
            alt=""
          />
        )} */}
        <h2 className="grid-1">
          A gente sabe como o sistema tributário brasileiro é complicado
        </h2>
        <div className="grid-2">
          <p>
            Por isso, combinamos nossa experiência na área fiscal com uma visão
            inovadora em tecnologia para te ajudar com:
          </p>
          <ul>
            <li>
              Dependência de plataformas difíceis de usar e pouco flexíveis
            </li>
            <li>
              Processos ineficientes e erros operacionais gerando prejuízos
              fiscais
            </li>
            <li>Perda de oportunidades de otimização da carga tributária</li>
            <li>Equipes de tax in-house desmotivadas</li>
          </ul>
        </div>
        <StaticImage
          src="../../../images/Ilustracao2.png"
          alt=""
          quality={100}
          className="grid-3"
          objectFit="contain"
          imgClassName="img-absolute"
        />
        <div className="grid-4">
          <h3>
            Menos trabalho com a preparação de arquivos SPED. Mais insights
            baseados em dados. Ganhos para toda a empresa.
          </h3>
          <div className="bubble-wrapper">
            <div className="bubble-container">
              <Bubble content="01" color="verde" />
              <div>
                <h4>Aumente sua produtividade</h4>
                <p>
                  Entregue arquivos do SPED com mais qualidade e em menos tempo.
                </p>
              </div>
            </div>
            <div className="bubble-container">
              <Bubble content="02" color="laranja" />
              <div>
                <h4>Melhores decisões</h4>
                <p>
                  Utilize os dados fiscais produzidos para obter insights
                  estratégicos para toda a empresa.
                </p>
              </div>
            </div>
            <div className="bubble-container">
              <Bubble content="03" color="amarelo" />
              <div>
                <h4>Sem complicação</h4>
                <p>
                  Soluções plug and play para você focar naquilo que faz de
                  melhor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </S.Detalhes>
  );
}
