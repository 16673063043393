import React, { useLayoutEffect, useRef, useState } from "react";

import { StaticImage } from "gatsby-plugin-image";

import paralax2 from "../../../images/paralax2.svg";
import { Container } from "../../../styles/UI";

import StyledLink from "../../../DesignSystem/StyledLink";
import Input from "../../../DesignSystem/Input";
import Button from "../../../DesignSystem/Button";
import Clientes from "../../../components/Clientes";

import * as S from "./style";
import { navigate } from "gatsby-link";

import { useViewportScroll, motion, useTransform } from "framer-motion";

export default function ClientesHome() {
  const [email, setEmail] = useState("");

  const handleClick = () => {
    // modalRef1.current.openModal();
    navigate("/baixar-taxsheets#formulario", { state: { email } });
  };

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setEmail(value);
  };

  const ref = useRef();
  const [offsetTop, setOffsetTop] = useState(0);

  useLayoutEffect(() => {
    if (!ref.current) return null;
    let bodyRect = document.body.getBoundingClientRect();
    let elementRect = ref.current.getBoundingClientRect();
    setOffsetTop(elementRect.top - bodyRect.top);
  }, [ref]);

  const { scrollY } = useViewportScroll();
  const y1 = useTransform(
    scrollY,
    [offsetTop - 600, offsetTop + 600],
    ["0%", "100%"]
  );

  return (
    <S.Clientes>
      <Container $clip>
        <div className="grid-1">
          {/* <motion.img
            style={{ y: y1 }}
            ref={ref}
            src={paralax2}
            className="paralax2"
            alt=""
          /> */}
          <p>Clientes que confiam na Taxcel</p>
          <h5>
          Mais de 740 clientes, dentre eles, 300 grandes corporações que estão transformando
          seus departamentos fiscais com a Taxcel
          </h5>
          <Clientes />
          <Input value={email} onChange={handleChange} placeholder="Seu email">
            <Button onClick={handleClick}>Testar trial</Button>
          </Input>
          <StyledLink mt={24} to="/planos" arrow>
            Conheça nossos planos
          </StyledLink>
        </div>
        <StaticImage
          src="../../../images/couple.jpg"
          alt="foto de casal usando tablet"
          quality={100}
          className="img-couple-wrapper"
          imgClassName="img-couple"
        />
      </Container>
    </S.Clientes>
  );
}
